.PageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;

  .Image {
    max-width: 90%;
    margin-bottom: 20px;
  }
}
