@import "../../styles/variables";

.NavBar {
  background-color: white;
  width: 100%;

  .NavBarContent {
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 20px;

    .Icons {
      margin-left: auto;
      display: flex;
    }
  }

  .SubNav {
    position: absolute;
    width: 100%;
    background-color: #eeeeee;
    z-index: 100;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.2s;
    font-size: 20px;

    .SubNavGrid {
      padding-left: 8rem;
    }

    a {
      padding: 1rem 1.5rem;

      &:hover {
        background-color: white;
        color: $vodafone-red;
      }
    }

    &.Visible {
      transform: scaleY(1);
    }
  }
}
