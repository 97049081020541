.SuccessContents {
  text-align: center;
  word-break: break-word;
  overflow-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  .BackButton {
    width: 328px;
    display: inline-block;
    padding-top: 32px;
    padding-bottom: 18px;
  }
}
