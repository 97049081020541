.AuditLogContainer {
  z-index: 10;
}

.StartDate,
.EndDate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > div {
    z-index: auto;
  }
}
