.FailureModalContent {
  text-align: center;
  word-break: break-word;
  overflow-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  .Buttons {
    display: flex;
    width: 100%;

    .Button {
      flex-grow: 1;
      padding: 0px 10px;
    }
  }
}
