.Breadcrumbs > nav > div {
  padding: 14px 0px;
  border: none;
  display: inline-flex;
  align-items: center;

  .LinkText {
    padding: 0px 8px;
    cursor: pointer;
  }

  .LinkTextLast {
    padding: 0px 8px;
    color: #999999;
  }
}
