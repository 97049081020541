.Banner {
  position: relative;
  height: 120px;
  overflow: hidden;

  &.flows {
    background-image: linear-gradient(to right, #ffeb9a 50%, #d1b073 50%);
  }

  &.users {
    background-image: linear-gradient(to right, #cebecb 50%, #a96faa 50%);
  }

  &.audit {
    background-image: linear-gradient(to right, #bcd08b 50%, #5ea15a 50%);
  }

  .BannerImage {
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);

    img {
      min-width: 1280px;
    }
  }

  .BannerContent {
    display: flex;
    position: relative;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);

    & > div {
      display: flex;
    }

    .Icon {
      margin-right: 20px;
    }
  }
}
