.Landing {
  background-image: url("../../assets/images/login_bg.png");
  background-image: -webkit-image-set(
    url("../../assets/images/login_bg.png") 1x,
    url("../../assets/images/login_bg@2x.png") 2x
  );
  background-image: image-set(
    url("../../assets/images/login_bg.png") 1x,
    url("../../assets/images/login_bg@2x.png") 2x
  );

  background-size: cover;
  background-position: 25% 50%;

  .WelcomeBlock {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    .VFLogo {
      width: 48px;
      height: 48px;
    }

    .LoginButtonContainer {
      position: relative;

      .MSLogo {
        position: absolute;
        margin-left: 16px;
        top: 50%;
        transform: translateY(-50%);
        height: 28px;
        width: 28px;
        z-index: 10;
        pointer-events: none;
      }
    }

    .SupportLink {
      text-align: center;
    }
  }
}
