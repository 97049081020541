$topbar-color: white;
$topbar-background-color: #222222;
$topbar-hover-color: #4a4d4e;
$bu-dropdown-background: #333333;
$bu-dropdown-border: #666666;

.TopBar {
  background-color: $topbar-background-color;
  height: 26px;

  .BUDropdownContainer {
    position: relative;
    display: inline-block;
    height: 100%;

    button {
      height: 100%;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: $topbar-color;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: $topbar-hover-color;
      }
    }

    .Dropdown {
      display: none;
      position: absolute;
      margin-top: 1px;
      z-index: 10;
      width: 325px;

      &.Open {
        display: block;
      }

      .BUDropdownOptions {
        background-color: $bu-dropdown-background;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .BUDropdownOption {
          padding: 0px 16px;
          cursor: pointer;

          button {
            background-color: transparent;
            border: none;
            border-top: 1px solid $bu-dropdown-border;
            cursor: pointer;
            color: $topbar-color;
            padding: 12px 0px;
            text-align: left;
            width: 100%;
            display: flex;
            align-items: center;

            & span:first-child {
              margin-right: 16px;
            }
          }

          &:hover,
          &.Selected {
            background-color: $topbar-hover-color;

            button,
            & + div > button {
              border-top-color: transparent;
            }
          }

          &:first-child {
            border-top: none;
          }
        }
      }
    }
  }
}
